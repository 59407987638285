import React from "react"
import firebase from "gatsby-plugin-firebase"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero/hero"
import Content from "../components/content/content"

import "../styles/styles.scss"

const IndexPage = () => {
  if (!!firebase.analytics && !!firebase.performance) {
    firebase.analytics()
    firebase.performance()
  }

  return (
    <Layout>
      <SEO
        title="Ogado"
        lang="it"
        keywords={[
          "ogado",
          "intrattenimento",
          "digitale",
          "film",
          "serie tv",
          "musica",
          "libri",
          "videogiochi",
          "the boys",
        ]}
      />
      <Hero />
      <Content />
    </Layout>
  )
}

export default IndexPage
